import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const defaultProps = {
  keywords: '',
  lang: 'en',
  meta: []
}

type SeoProps = {
  description?: string
  lang?: string
  meta?: Array<{
    name?: string
    property?: string
    content: string
  }>
  /** meta keywords for the page */
  keywords?: string
  title?: string
  image?: {
    src: string
    height: string
    width: string
  }
} & typeof defaultProps

function Seo({ description, lang, meta, keywords, title, image }: SeoProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image && image.src ? `${site.siteMetadata.siteUrl}/${image.src}` : null
  const pageMeta = [
    {
      name: 'description',
      content: metaDescription
    },
    {
      name: 'keywords',
      //   content: site.siteMetadata.keywords.join(',')
      content: ''
    },
    {
      property: 'og:title',
      content: title
    },
    {
      property: 'og:description',
      content: metaDescription
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      name: 'twitter:title',
      content: title
    },
    {
      name: 'twitter:description',
      content: metaDescription
    },
    {
      name: 'Cache-Control',
      content: 'no-cache, no-store, must-revalidate'
    },
    {
      name: 'Pragma',
      content: 'no-cache'
    },
    {
      name: 'Expires',
      content: '0'
    }
  ]
    .concat(
      keywords.length > 0
        ? {
            content: keywords.join(', '),
            name: 'keywords'
          }
        : []
    )
    .concat(
      image
        ? [
            {
              property: 'og:image',
              content: metaImage
            },
            {
              property: 'og:image:width',
              content: image.width
            },
            {
              property: 'og:image:height',
              content: image.height
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image'
            }
          ]
        : [
            {
              name: 'twitter:card',
              content: 'summary'
            }
          ]
    )
    .concat(meta)
  return <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={`%s | ${site.siteMetadata.title}`} meta={pageMeta} />
}

Seo.defaultProps = defaultProps

export default Seo
