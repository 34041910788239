import * as React from 'react'
import styled from '@emotion/styled'

import { heights, dimensions, colors } from '../styles/variables'
import Container from './Container'

const StyledHeader = styled.header`
  height: ${heights.header}px;
  padding: 0 ${dimensions.containerPadding}rem;
  background-color: ${colors.defaultText};
`

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const Header: React.FC = () => (
  <StyledHeader>
    <HeaderInner />
  </StyledHeader>
)

export default Header
